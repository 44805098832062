<template>
  <div class="tasks">
    <div class="card" style="width: 24rem;">
      <div class="card-header">
        Tasks
      </div>
      <TaskCard v-for="task in tasks" :key="task.id" :task="task"/>
  </div>
  </div>
</template>

<script>
import TaskCard from '../components/TaskCard.vue'

import TasksService from '@/services/TasksService.js'

export default {
  name: 'TasksList',
  components: {
    TaskCard
  },
  data () {
    return {
      tasks: [
        
      ]
    }
  },
  created(){
    //axios.get('http://my-json-server.typicode.com/MCAST-GeorgeSammut/MyJSONServer/tasks')
    TasksService.getTasks()
    .then(response => {
      //console.log()
      this.tasks = response.data
    })
    .catch(error => {
      console.log('ERRORS' + error)
    })
  },
}
</script>

<style scoped>
.card{
  border: 1px solid green;
  width: 95%;
  margin: auto;
}
</style>
